// translations.js
export default {
  en: {
    fentec: "FenTec",
    mobility: "Mobility",
    header: {
      title: 'Fentec Mobility',
      language: 'Language',
      download: 'Download App'
    },
    followUs: "Follow us",
    termsAndConditions: 'Terms and conditions',
    serviceAgreement: 'Service agreement',
    privacyPolicy: 'Privacy policy',
    home: {
      hero: {
        title: 'FenTec Mobility ',
        subtitle: 'is the first intelligent, shared, free and eco-friendly transport service in Algeria and the Maghreb.',
        button: 'More'
      },
      popup: {
        content:
          `The problem of traffic jams, fuel costs, air and noise pollution have necessitated us thinking to find solutions. <br>
        Fentec Mobility is the first integrated system offering shared electric vehicles in Algeria and The Arab Maghreb.  <br>
        We aim to transform the current mode of transportation in the cities to an enjoyable, safe, sustainable, eco-friendly, shareable and affordable mode. <br>
        We are on a mission to replace all car trips in cities and Universities under 15km. <br>
        Safety First, The helmet is available for all our vehicles. <br>
        With an easy-to-use mobile app and a team of experts available at any time for a better experience.`,
        content2:
          `Fentec Mobility Your Solution <br>
        to a Green and Sustainable Future.`
      },
      slider: {
        slide1: 'Slide 1',
        slide2: 'Slide 2',
        slide3: 'Slide 3',
        slide4: 'Slide 4',
        slide5: 'Slide 5',
        slide6: 'Slide 6'
      },
      comingSoon: {
        title: 'Coming soon, stay tuned!',
        content: 'The first complete eco-friendly transportation system in Algeria and The Arab-Maghreb. Made by ALGERIANS.'
      },
      joinUs: {
        title: 'Join us!',
        pointSeller: 'As Point Seller',
        accountant: 'As Accountant',
        technician: 'As Technician'
      },
    },
    footer: {
      content: `Go safely, freely and enjoyably.<br>
<span style="font-size: 14px;">Together Towards a Sustainable World.</span>`,
    }
  },
  ar: {
    fentec: "فينتك",
    mobility: "موبيليتي",
    header: {
      title: 'فينتك موبيلتي',
      language: 'اللغة',
      download: 'تحميل التطبيق'
    },
    home: {
      hero: {
        title: 'فانتك موبيليتي',
        subtitle: ' هي أول خدمة تنقل ذكية، تشاركية، حرة و صديقة للبيئة في الجزائر و المغرب العربي.',
        button: 'المزيد'
      },
      popup: {
        content:
          `مشكلة الازدحام المروري وتكاليف الوقود وتلوث الهواء والضوضاء دفعتنا للتفكير في إيجاد حلول. <br>
  فينتك موبيليتي هو النظام المتكامل الأول الذي يوفر المركبات الكهربائية المشتركة في الجزائر والمغرب العربي. <br>
  نهدف إلى تحويل وسيلة النقل الحالية في المدن إلى وسيلة ممتعة، آمنة، مستدامة، صديقة للبيئة، مشتركة وميسورة التكلفة. <br>
  نحن في مهمة لاستبدال جميع الرحلات بالسيارات في المدن والجامعات التي تقل عن 15 كيلومترًا. <br>
  الأمان أولاً، يتوفر الخوذ لجميع مركباتنا. <br>
  مع تطبيق جوال سهل الاستخدام وفريق من الخبراء متاح في أي وقت لتجربة أفضل.`,
        content2:
          `فينتك موبيليتي حلّك <br>
من أجل مستقبل أخضر ومستدام.`
      },
      slider: {
        slide1: 'الشريحة 1',
        slide2: 'الشريحة 2',
        slide3: 'الشريحة 3',
        slide4: 'الشريحة 4',
        slide5: 'الشريحة 5',
        slide6: 'الشريحة 6'
      },
      comingSoon: {
        title: 'قريبا، ابقوا على اتصال!',
        content: 'أول نظام نقل كامل صديق للبيئة في الجزائر والمغرب العربي. من صنع الجزائريين.'
      },
      joinUs: {
        title: 'انضم إلينا!',
        pointSeller: 'كبائع نقطة',
        accountant: 'كمحاسب',
        technician: 'كفني'
      },
    },
    footer: {
      content: `تنقل بأمان وحرية واستمتاع.<br>
<span style="font-size: 14px;">معًا نحو عالم مستدام.</span>`,
    },
    followUs: "تابعنا",
    termsAndConditions: 'الشروط والأحكام',
    serviceAgreement: 'اتفاقية الخدمة',
    privacyPolicy: 'سياسة الخصوصية'
  },
  fr: {
    fentec: "FenTec",
    mobility: "Mobility",
    header: {
      title: 'Fentec Mobility',
      language: 'Langue',
      download: 'Télécharger l\'application'
    },
    followUs: "Suivez-nous",
    termsAndConditions: 'Conditions générales',
    serviceAgreement: 'Contrat de service',
    privacyPolicy: 'Politique de confidentialité',
    home: {
      hero: {
        title: 'FenTec Mobility',
        subtitle: 'est le premier service de transport intelligent, partagé, libre et  écologique en Algérie et au Maghreb.',
        button: 'Plus'
      },
      popup: {
        content:
          `Le problème des embouteillages, des coûts du carburant, de la pollution de l'air et du bruit nous a poussés à réfléchir à des solutions. <br>
  Fentec Mobility est le premier système intégré offrant des véhicules électriques partagés en Algérie et au Maghreb arabe. <br>
  Nous visons à transformer le mode de transport actuel dans les villes en un mode agréable, sûr, durable, écologique, partageable et abordable. <br>
  Nous sommes en mission pour remplacer tous les trajets en voiture dans les villes et universités de moins de 15 km. <br>
  La sécurité avant tout, le casque est disponible pour tous nos véhicules. <br>
  Avec une application mobile facile à utiliser et une équipe d'experts disponible à tout moment pour une meilleure expérience.`,
        content2: `Fentec Mobility Votre solution <br>
pour un avenir vert et durable.`
      },
      slider: {
        slide1: 'Diapositive 1',
        slide2: 'Diapositive 2',
        slide3: 'Diapositive 3',
        slide4: 'Diapositive 4',
        slide5: 'Diapositive 5',
        slide6: 'Diapositive 6'
      },
      comingSoon: {
        title: 'Bientôt, restez à l\'écoute !',
        content: 'Le premier système de transport complet écologique en Algérie et au Maghreb arabe. Réalisé par des Algériens.'
      },
      joinUs: {
        title: 'Rejoignez-nous !',
        pointSeller: 'En tant que vendeur de points',
        accountant: 'En tant que comptable',
        technician: 'En tant que technicien'
      },
    },
    footer: {
      content: `Allez en toute sécurité, librement et agréablement. <br>
<span style="font-size: 14px;">Ensemble vers un monde durable.</span>
`,
    }
  }
}